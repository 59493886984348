import React, { useState, useEffect, Fragment } from 'react';
import './App.css';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Alert,
  Input
} from 'reactstrap';

import Header from './Components/Header';

import { BsFillMicFill, BsFillMicMuteFill } from 'react-icons/bs';
import { FaCopy } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';

import { message } from 'antd';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = 'en-EN';

function App() {

  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState("");
  const [text, setText] = useState("");
  const [savedNotes, setSavedNotes] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    handleListen();
    // eslint-disable-next-line
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        mic.start();
      }
    }
    else {
      mic.stop();
      mic.onend = () => {
        message.info('Mic is off');
      }
    }

    mic.onstart = () => {
      message.success('Mic is on');
    }

    mic.onresult = event => {
      // Result is an array of all the words spoken
      const transcript = Array.from(event.results)
        // Map through the array and return the transcript
        .map(result => result[0])
        .map(result => result.transcript)
        // Join the array into a string
        .join('');
      // Set the note to the transcript
      setNote(transcript);
      mic.onerror = event => {
        message.error(event.error);
      }
    }
  }

const handleSave = async () => {
  // Save user note to the state
  const userNote = { type: 'user', content: text || note };
  setSavedNotes([...savedNotes, userNote]);

  // Clear the note and text input
  setNote('');
  setText('');

  // Stop the microphone
  setIsListening(false);

  // Show a success message
  message.success('Note saved');

  // Log the text to the console (for debugging purposes)
  console.log('Input text:', text);

  // Wait for 2 seconds
  await new Promise(resolve => setTimeout(resolve, 2000));

  // Save GPT-3 response to the state
  const chatGPTResponse = await fetchChatGPTResponse(text || userNote.content);
  const gptResponse = { type: 'gpt', content: chatGPTResponse };
  setSavedNotes([...savedNotes, gptResponse]);

  // Save the conversation to localStorage
  localStorage.setItem('savedNotes', JSON.stringify([...savedNotes, userNote, gptResponse]));
};


const fetchChatGPTResponse = async (inputText) => {
  try {
    const apiKey = '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'; // Replace with your actual OpenAI API key
    const endpoint = 'https://api.openai.com/v1/completions';
    console.log(inputText);
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'text-davinci-003', // Specify the GPT-3 model
        prompt: inputText,
        max_tokens: 250,
        temperature: 0.5
        // Add other parameters as needed for your use case
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error from OpenAI API:', errorData.error);
      return '';
    }

    const data = await response.json();
    const chatGPTResponse = data.choices && data.choices.length > 0 ? data.choices[0].text : '';
    console.log(chatGPTResponse)
    return chatGPTResponse;
  } catch (error) {
    console.error('Error fetching ChatGPT response:', error);
    return '';
  }
};




const handleCopy = ({ target }) => {
  message.success('Copied to clipboard');
  setCopied(true);

  setTimeout(() => {
    setCopied(false);
  }, 2000);
};



  return (
    <Fragment>
      {/*<Header />*/}

      <Container className='mt-5'>
        <Row>
          <Col md={6}>
            <Card className='bg-light h-100 border-0'>
              <CardBody>
                <CardTitle>
                  <h3>Transcript</h3>
                </CardTitle>
                <hr />
                {
                  isListening ? (
                    <div className='d-flex justify-content-center mx-auto'>
                      <BsFillMicFill className='fs-4 me-2 text-danger' />
                      <h5 className='text-danger'>Listening...</h5>
                    </div>
                  )
                    : (
                      <Alert className='d-flex justify-content-center mx-auto text-center' color="primary">
                        <BsFillMicMuteFill className='fs-4' />
                        <h5>Press the Start Button to record the Voice</h5>
                      </Alert>
                    )}

                <h4 className='text-center my-3'>OR</h4>

                <Input type="textarea" className='text-field w-100 mb-3' rows={5} value={text} onChange={(e) => setText(e.target.value)} placeholder="Write your note..."></Input>

                <div className='d-flex justify-content-end me-auto gap-2'>
                  <Button onClick={() => setIsListening(prevState => !prevState)} outline color={isListening ? 'warning' : 'success'}>{isListening ? "Stop" : "Start"}</Button>
                  <Button onClick={handleSave} disabled={note || text ? false : true} color='dark'>Save Note</Button>
                </div>
                <hr />
                <CardText>
                 <h5>Preview !!</h5>
                 <div>{note || text ? note || text : (<p>Nothing to Show !!</p>)}</div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className='bg-light h-100 border-0 right-card'>
              <CardBody>
                <CardTitle>
                  <h3>Response</h3>
                </CardTitle>
                <hr />
                <CardText>
                  <h5>Preview !!</h5>
                  {savedNotes.slice().reverse().map((message, index) => (
                    message.type === 'gpt' && (
                      <div key={index} className='saved-note'>
                        <p>{message.content}</p>
                      </div>
                    )
                  ))}
                </CardText>
                <CopyToClipboard text={localStorage.getItem('savedNotes') || localStorage.getItem('text')}>
                  <Button onClick={handleCopy} disabled={localStorage.getItem('savedNotes') || localStorage.getItem('text') ? false : true} className='float-end fs-5' id='copy' color={copied ? 'success' : 'warning'}>{copied ? (<TiTick className='icon' />) : (<FaCopy className='icon' />)}</Button>
                </CopyToClipboard>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default App;
